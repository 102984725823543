<!-- <div class="dialog">
    <div class="dialog_header">
       
        {{ "general.notifications" | translate }}
    </div> -->

<div class="notification_list p-0" mat-dialog-content infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="notificationListModel.perPage" (scrolled)="getNextPage()">
    <!-- <div class="notification_header">
        {{ "general.notifications" | translate }}
    </div> -->
    <div class="notification_record" *ngFor="let item of notificationList" [class.cursor_pointer]="item.web_link"
        (click)="onNavigate(item.web_link)">
        <div class="image">
            @if (item.image) {
            <img [src]="item?.image" (error)="item.image=''" alt="Noti. Image">
            } @else if(!item.image && item.user_name){
            <span>{{item.user_name|shortName}}</span> 
            }@else {
            <img src="./assets/images/OfferX-App-Icon.png"  alt="Noti. Image">
            }
        </div>
        <div class="content">
            <div class="title">{{item?.message}}</div>
            <div class="description" *ngIf="item?.sub_title" [innerText]="item?.sub_title">

            </div>
            <div class="description" *ngIf="item?.user_name">
                {{item?.user_name}}
            </div>
            <div class="date">
                <span class="day">{{item.created_at | dateAgo}}</span>
                {{item?.created_at | date:'d MMMM yyyy - h:mm a'}}
            </div>
        </div>
    </div>

    <div *ngIf='loading' class="dataLoading py-3">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>

    <empty-state *ngIf="!notificationList?.length && !loading"
        [title]='"general.No Data Found" | translate'></empty-state>
</div>
<!-- </div> -->