<img src="/assets/images/covers/interests.svg" alt="image">
<div class="main_content">

    <div class="header">
        <div class="title">
            <span> {{"interstes.Welcome"|translate}} </span>
            {{currentUser?.full_name}}
        </div>
        <div class="sub_title">
            {{"interstes.We wish you an enjoyable experience with us"|translate}}
        </div>
    </div>

    <div class="desc">
        {{"interstes.Please select your preferred offer categories that interest you"|translate}}
    </div>
    <div class="sub_desc">
        {{"interstes.Your choice will help us to provide the best offers that suit you"|translate}}
    </div>
    <div *ngIf="error" class="has-error">{{"form_message.Required"|translate}}</div>

    <div class="categories" *ngIf="!loading_data" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="ListingModel.perPage" (scrolled)="getNextPage()">
        <ng-container *ngFor="let item of categoriesList">
            <div class="category" *ngIf="item?.child?.length">
                <div class="name"> {{item.name}}</div>
                <mat-selection-list class="selection-btns row" [(ngModel)]="item.selected"
                    [ngModelOptions]="{standalone:true}">
                    <div class="" *ngFor="let category of item.child;let i = index">
                        <mat-list-option [value]="category.id">
                            {{category.name}}</mat-list-option>
                    </div>
                </mat-selection-list>
            </div>
        </ng-container>


    </div>


    <div *ngIf='loading_data || loadingMore' class="dataLoading py-5">
        <mat-spinner class="m-auto" diameter="50"></mat-spinner>
    </div>









</div>
<div class="btn_group" mat-dialog-actions>
    <button mat-button mat-dialog-close class="btn-secondary" (click)="OnSkip()">{{"interstes.Skip"|translate}}</button>
    <button mat-button class="btn-primary" (click)="OnSubmit()" [class.loading]="loading">
        {{"interstes.Start"|translate}}
    </button>
</div>