import { Component, ElementRef, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './services/StorageService';
import { AuthenticationService, hexToRgb } from './services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { SocketService, SocketEventType } from './services/socket.service';
import { OfferQRCodeComponent } from './shared/offer-card/offer-details/offer-qr-code/offer-qr-code.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'Offer-X-app';
  subscribetions: Subscription[] = []

  constructor(
    public storageService: StorageService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    public _authService: AuthenticationService,
    private _dialog: MatDialog,
    private _socket: SocketService

    // private sw: UpdateAppService,
    // upToDateService: UpToDateBuildService
  ) {
    // this.sw.checkForUpdates();
    // let buildNumber = upToDateService.buildNumberAtStartup;
    // upToDateService.buildIsUpToDate.subscribe(buildIsUpToDate => {
    //   if (!buildIsUpToDate) {
    //     Swal.fire({
    //       title: this.translate.instant('general.New app version found.'),
    //       icon: 'info',
    //       confirmButtonText: this.translate.instant('swalMsg.Reload'),
    //     }).then(() => {
    //       window.location.reload();
    //     });
    //   }
    // });

    this.storageService.changes.subscribe(data => {
      if (data?.key == 'language' || data?.key == 'authUser') {
        window.location.reload();
      }
    });

    var lang = localStorage.getItem('language');
    if (lang == 'ar' || lang == 'en') {
      translate.use(lang);
    } else {
      lang = 'ar';
      translate.use('ar');
      localStorage.setItem("language", lang)
    }

    var style = './assets/css/en-US/style.css';
    var dir = 'ltr';
    if (lang == 'ar') {
      style = './assets/css/ar-SA/style.css';
      dir = 'rtl';
    }
    let buildNumber = '1.0.3'
    style += buildNumber ? '?vr=' + buildNumber : '';
    document.getElementById('mainStyle')?.setAttribute('href', style);
    document.getElementById('html')?.setAttribute('lang', lang);
    document.getElementById('html')?.setAttribute('dir', dir);
  }
  ngOnInit(): void {
    let color = this._authService.currentUserValue?.company?.color
    let text_color = this._authService.currentUserValue?.company?.font_color
    if (this._authService.currentUserValue) {
      document.documentElement.style.setProperty('--main-color', color);
      document.documentElement.style.setProperty('--main-text-color', text_color);
      console.info(`color:${color} , font_color: ${text_color}`);
      this.ListenOnSocketEvent();
    } else {
      document.documentElement.style.setProperty('--main-color', '73, 214, 214');
      document.documentElement.style.setProperty('--main-text-color', "#123636");
    }


  }



  ListenOnSocketEvent() {
    this.subscribetions.push(this._socket.getDataStream(SocketEventType.Redeemed_Offers).subscribe((result: any) => {
      this._dialog.closeAll();
      // this._dialog.afterAllClosed.subscribe(res => {
      const dialogRef = this._dialog.open(OfferQRCodeComponent, {
        width: "540px",
        data: { Offer_ID: result.data.offer_id }
      })
      // dialogRef.afterClosed().subscribe((res: any) => {
      //   // if (res) {
      //   //   this.GetOfferDetails()
      //   // }
      // })
      // })
    }))
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscribetions.forEach((item) => item.unsubscribe);
  }


}
