import { ElementRef, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ActivatedRoute } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { NotAuthorizedComponent } from './features/pages/not-authorized/not-authorized.component';
import { ErrorComponent } from './features/pages/error/error.component';
import { SharedModule } from "./shared/shared.module";
import { InterestsLoginComponent } from './features/pages/interests-login/interests-login.component';
import { PanelLayoutComponent } from './layouts/panelLayout/panelLayout.component';
import { NavbarComponent } from './layouts/panelLayout/navbar/navbar.component';
import { MetertToKilometerPipe } from './helpers/metert-to-kilometer.pipe';
import { NotificationListComponent } from './layouts/panelLayout/notification-list/notification-list.component';
import { AppSharedModule } from './features/landing/AppShared.module';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    BasicLayoutComponent,
    EmptyLayoutComponent,
    PanelLayoutComponent,
    NotAuthorizedComponent,
    ErrorComponent,
    InterestsLoginComponent,

    NavbarComponent,
    NotificationListComponent,

  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgSelectModule,
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem('language') || 'ar',
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      },
      // missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
    }),
    AppSharedModule,
    SharedModule,
  ]
})
export class AppModule {
  constructor(
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['lang'] && (params['lang'] == 'ar' || params['lang'] == 'en')) {
        if (localStorage.getItem('language') !== params['lang']) {
          translate.use(params['lang']);
          localStorage.setItem('language', params['lang']);
          location.reload();
        }
      }
    });
  }
}
