import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListingModel } from '../../../features/panel/models/general';
import { NavigationService } from '../../../services/navigation.service';
import { ConfigService } from '../../../helpers/config.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styles: ``
})
export class NotificationListComponent implements OnInit {
  notificationListModel: ListingModel = new ListingModel;
  totalNotifications: number = 0;
  notificationList: NotificationRecordInterface[] = [];
  loading: boolean = false;
  Noti_readed: boolean = false;

  @Output() Nots_Readed: EventEmitter<boolean> = new EventEmitter();
  @Output() Closed: EventEmitter<boolean> = new EventEmitter();

  constructor(
    // public dialogRef: MatDialogRef<NotificationListComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private navService: NavigationService,
    private _config: ConfigService) {
  }

  ngOnInit(): void {
    this.notificationListModel.perPage = 20;
    this.GetNotificationsList()
  }


  GetNotificationsList() {
    this.loading = true;
    let model = { ...this.notificationListModel };
    model.page += 1;
    this.navService.GetNotifiactions(model).subscribe((result) => {
      this.notificationList = this.notificationList.concat(result.data.list);
      this.totalNotifications = result.data.total;
      this.Nots_Readed.emit(true);
      this.loading = false;
      if (model.page == 1 && result.data.count_unread > 0) {
        this.MarkAllAsRead();
      }
    })
  }


  MarkAllAsRead() {
    this.navService.MarkAllNotificationsAsRead().subscribe((result) => {
      this.Noti_readed = true;
    })
  }

  getNextPage() {
    if (this.totalNotifications > this.notificationList.length) {
      this.notificationListModel.page += 1;
      this.GetNotificationsList();
    }
  }

  onNavigate(link: string) {
    if (link) {
      // this.dialogRef.close(this.Noti_readed);
      this._config.goTo(link);
    }
    this.Closed.emit(true);

  }

}



export interface NotificationRecordInterface {
  not_id: number
  user_type: string
  is_seen: number
  web_link: any
  created_at: string
  message: string
  sub_title: any
  image: string
  user_name: string
}


