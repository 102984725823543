import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { ErrorComponent } from './features/pages/error/error.component';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { VerifyAccountComponent } from './features/pages/verify-account/verify-account.component';
import { InterestsLoginComponent } from './features/pages/interests-login/interests-login.component';
import { PanelLayoutComponent } from './layouts/panelLayout/panelLayout.component';
import { AuthGuard } from './helpers/auth.guard';
import { LoginComponent } from './features/auth/components/login/login.component';
import { ResetPasswordComponent } from './features/auth/components/reset-password/reset-password.component';
import { LandingComponent } from './features/landing/landing.component';
import { VerifyChangesComponent } from './features/pages/verify-changes/verify-changes.component';

const routes: Routes = [
  { path: '', component: LandingComponent, loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule) },
  {
    path: '', component: BasicLayoutComponent, children: [
      { path: 'set-password/:token/:phone', component: VerifyAccountComponent },
      { path: 'reset-password/:id/:phone', component: ResetPasswordComponent },
      { path: 'verify-changes/:token', component: VerifyChangesComponent },
      { path: 'interests', component: InterestsLoginComponent },
      // { path: '', redirectTo: '/login', pathMatch: 'full' },
    ]
  },
  { path: 'login', component: EmptyLayoutComponent, loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule) },

  {
    path: 'panel', component: PanelLayoutComponent, loadChildren: () => import('./features/panel/panel.module').then(m => m.PanelModule),
    canActivate: [AuthGuard]
  },
  { path: '**', component: ErrorComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
